.table-container{
    background-color: white;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 40px;
    border:1px solid #edf2f9;
    box-shadow: 0 .75rem 1.5rem rgba(18,38,63,.03);
    padding:0px;
    display: flex;
    justify-content: center;
}
.table-container-inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.table-functions{
    padding: 20px;
}
.table {
    overflow:scroll;
    flex-grow:1;
}
.table > table{
   width: 100%;
   border-collapse: separate;
   border-spacing: 0px;
   flex-grow: 1;
}
.table > table > th:first-child{
    padding-left: 1.5rem!important;
}
.table > table > tr > td:first-child{
    padding-left: 1.5rem!important;
}

.table-functions > div:nth-child(2){
    display: flex;
    justify-content: flex-end;
    padding: 0px;
    align-items: center;
}
.btn-primary {
background-color: red !important;
}
.table > table > th {
    background-color: #f9fbfd;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: .08em;
    color: #95aac9!important;
    border-bottom-width: 1px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #edf2f9;
    border-top:1px solid #edf2f9;
}
.table > table > tr > td{
    font-size: 14px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #edf2f9;
    text-align:left;
     width: 1px;
    white-space: nowrap;
    word-wrap:  break-word;
}
.table-container > div > div > div > svg{
    height: 22px;
    width: 15px;
    fill:#95aac9;
    margin-right: 10px;
}
.table-container > div > div > div > input{
    border:none;
    outline: none;
    font-size: 14px;
    color:#12263f;
}

::-webkit-input-placeholder { /* Edge */
    color: #95aac9ce;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #95aac9ce;
  }
  
  ::placeholder {
    color: #95aac9ce;
  }
  .checkbox{
    font-size: 25px;
  }
  .pagination-row{
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      padding-right: 10px;
      padding-left: 10px;
  }

  @media only screen and (max-width: 600px) {
    .pagination-row{
        justify-content: center;
    }
  }
  .loading-row{
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  svg{
      height: 20px;
      width: 20px;
  }
  .center{
    display: table-cell;
      text-align: center !important;
      
  }