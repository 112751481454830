.input-text{
    color:black;
    font-family: 'Cerebri-Regular';
    font-size: 14px;
    padding-top: 30px;
    padding-bottom: 5px;
}
.select-row > div{
    width: 100%;
 
}
.select-row > div > div {
    border:1px solid #d2ddec;
    background-color: white;
}
.select-row > div > div:hover {
    border:1px solid #d2ddec;
}
.input-text{
    padding-top: 10px;
}
.select-row input[type=text]{
    width:100% !important;
}
.error-row{
    padding-top: 20px;
    color:red;
    font-size: 13px;
}