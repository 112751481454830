.login-row{
    display: flex;
    justify-content: center;
    height: 100%;
}
.login-row > div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}
.login-title{
    display: flex;
    justify-content: center;
    font-family: 'Cerebri-SemiBold';
    font-size: 2rem;
}
.login-title > img{
    height: 109.5px;
    width: 300px;
    margin-bottom:30px ;
}
.main-container{
    height: 100%;
}
.login-tag-line{
    display: flex;
    justify-content: center;
    color:#95aac9;
    align-items: center;
    font-family: 'Cerebri-Regular';
    font-size: 1rem;
}
.input-text{
    color:black;
    font-family: 'Cerebri-Regular';
    font-size: 0.9rem;
    padding-top: 30px;
    padding-bottom: 5px;
}
.forgot-password{
    display: flex;
    justify-content: flex-end;
    color:#95aac9;
    cursor: pointer;
}
.input-text > div{
    padding:0px;
}
.signin-button{
    padding-left: 30px;
    padding-right: 30px;
    width:100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 30px;
    font-family: 'Cerebri-Regular';
    font-size: 0.9rem;
    border-radius: .5rem;
    background-color: #2c7be5;
}
.sign-in-button:hover {
    color: #fff;
    background-color: #1a68d1;
    border-color: #1862c6;
    }
.sign-up-row{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.no-account-text{
    color:#95aac9;
    font-family: 'Cerebri-Regular';
    font-size: 0.9rem;
}
.sign-up-text{
    color:#007bff;
    font-family: 'Cerebri-Regular';
    font-size: 0.9rem;
    padding-left:5px;
    cursor: pointer;
}
.error-row{
    color:red;
    font-family: 'Cerebri-Regular';
    font-size: 0.9rem;
    padding-top:10px;
    height:15px;
}

