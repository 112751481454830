html, body, #root, .App {
  height: 100%;
}
@font-face {
  font-family: 'Cerebri-Regular';
  src: local('Cerebri'), url(./fonts/cerebrisans-regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Cerebri-Bold';
  src: local('Cerebri-Bold'), url(./fonts/cerebrisans-bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Cerebri-Book';
  src: local('Cerebri-Book'), url(./fonts/cerebrisans-book.ttf) format('truetype');
}
@font-face {
  font-family: 'Cerebri-Medium';
  src: local('Cerebri-Medium'), url(./fonts/cerebrisans-medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Cerebri-SemiBold';
  src: local('Cerebri-SemiBold'), url(./fonts/cerebrisans-semibold.ttf) format('truetype');
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem .75rem;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #d2ddec;
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #d2ddec;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #d2ddec;
}
#react-paginate ul {
  display: inline-block;
  padding:0px;
}
#react-paginate{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
#react-paginate li {
  display: inline-block;
  text-align: center;
  min-width: 40px;
  color:#12263f;
  padding-top: 10px;
  padding-bottom: 10px;
  border-left:1px solid #e3ebf6;
  border-top:1px solid #e3ebf6;
  border-bottom:1px solid #e3ebf6;

}
#react-paginate li a{
  padding:10px;
  outline: none;

}
#react-paginate .next{
  border-top-right-radius:12px;
  border-bottom-right-radius:12px;
  border:1px solid #edf2f9;
}
#react-paginate .previous{
  border-top-left-radius:12px;
  border-bottom-left-radius:12px;
  border:1px solid #edf2f9;
  border-right: none;
}
#react-paginate .selected{
  background-color:#f9fbfd;
  color:#12263f;
}
.dropdown > .dropdown-toggle, .dropdown > .btn ,.dropdown >  .btn-primary, .dropdown > .btn-primary:hover, .dropdown > .btn-primary:not(:disabled):not(.disabled).active, .dropdown > .btn-primary:not(:disabled):not(.disabled):active, .dropdown >  .show>.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #6e84a3 !important;
  border-color: #6e84a3 !important;
  outline: none !important;
  }