
.container{
   border-bottom:1px solid #e3ebf6;
   padding-top: 20px;
}
.overview-row{
    font-family: 'Cerebri-SemiBold';
    font-size: 10px;
    color:#95aac9;
    letter-spacing: .08em;
}
.container > div > div{
    padding:0px;
}
.container > div > div:nth-child(2){
  display: flex;
  justify-content: flex-end;
}
.title-row{
    font-family: 'Cerebri-SemiBold';
    font-size: 25px;
    color:black;
    padding-bottom: 30px;
}

.btn{
    display: inline-block;
    font-family: 'Cerebri-Regular';
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: .5rem .75rem;
    font-size: .9375rem;
    line-height: 1.5;
    border-radius: .375rem;
    top:0px;
    position: relative;
    top: 0;
    transition: top ease 0.5s;
}
.btn:hover{
   top:-5px;
}