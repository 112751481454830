.main-container{
    height:100%;
    background-color: #f9fbfd;
    overflow:scroll;
}
.content-row{
    height:100%;
}
.menu-col{
    width:250px;
    border-color: #e3ebf6;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    height: 100%;
    background-color: white;
}
.side-menu{
    float: right;
}
.content-col{
    min-height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    overflow-y: scroll;
}
@media only screen and (max-width: 768px) {
    .content-col{
        padding-top: 65px;
    }
}
@media only screen and (min-width: 768px) {
    .content-col{
        min-height: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
}
.logo-row{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.logo-row >  img {
    height: 36.5px;
    width:100px;
}