.main-container{
    background-color: white;
    width: 100vw;
    padding:0px;
}
.accordion{
    width: 100%;
    background-color: white;
}
.no-border{
    border:none;
    border-style: solid;
    border-color: #e3ebf6;
    border-width: 0px 0px 1px 0px;
   
}
.menu-card{
    background-color: white;
}
.menu-card-2{
    border-color: #e3ebf6;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    background-color: white;
    
}
.header-bar{
    height: 100%;
    height: 35px;
    display: grid;
    align-items: center;
    padding: 0px;
}
.header-bar > div > div {
    padding:0px;
}
.menu-icon{
    height: 20px;
    width: 20px;
    fill:#6e84a3;
}
.menu-icon:hover{
    height: 20px;
    width: 20px;
    fill:#12263f;
}
.logo-col{
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo-col > img{
    height: 30px;
    width: 80px;
}
