
.accordion{
    width: 100%;
    background-color: white;
}
.accordion > div > div{
    background-color:white;
    border:none;
    display: inline-flex;
}
.accordion > div {
    background-color:white;
    border:none;
    cursor: pointer;
    font-family: 'Cerebri-Regular';
    font-size: 15px;
    color:#6e84a3;
}

.accordion-hover{
    width: 100%;
    background-color: white;
    color:#12263f;
}
.accordion-hover > div > div{
    background-color:white;
    border:none;
    display: inline-flex;
}
.accordion-hover > div {
    background-color:white;
    border:none;
    cursor: pointer;
    font-family: 'Cerebri-Regular';
    font-size: 15px;
    color:#12263f;
}
.menu-icon{
    height: 17px;
    width: 17px;
    fill:#6e84a3;
    margin-right: 10px;
}
.menu-icon-hover{
    height: 17px;
    width: 17px;
    fill:#12263f;
    margin-right: 10px;
}

.arrow-icon{
    height: 10px;
    width: 10px;
    fill:#6e84a3;
    transform: rotate(90deg);
}
.arrow-icon-hover{
    height: 10px;
    width: 10px;
    fill:#12263f;
    transform: rotate(90deg);
}
.arrow-icon-r{
    height: 10px;
    width: 10px;
    fill:#6e84a3;
    transform: rotate(-90deg);
}
.arrow-icon-hover-r{
    height: 10px;
    width: 10px;
    fill:#12263f;
    transform: rotate(-90deg);
}
.child-item{
    font-family: 'Cerebri-Regular';
    font-size: 13px;
    color:#6e84a3;
    margin-left: 27px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.child-item-hover{
    font-family: 'Cerebri-Regular';
    font-size: 13px;
    color:#12263f;
    margin-left: 27px;
    padding-top: 10px;
    padding-bottom: 10px;
}